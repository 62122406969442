import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import Itinerari from '@components/Itinerari/Itinerari';

const ItinerariPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Itinerari E-Bike consigliati - Tiziano Bieller Sport"
        lang="it"
      />
      <Itinerari />
    </Layout>
  );
};

export default ItinerariPage;
